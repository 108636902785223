/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 09:57:21
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 21:37:59
 */
import { numberFormat } from '@/plugins/utils'

export default {
  name: 'NProgress',
  props: {
    total: {
      type: Number,
      default: 10000
    },
    current: {
      type: Number,
      default: 5000
    }
  },
  computed: {
    // 格式化数字显示
    formatTotal () {
      return numberFormat(this.total)
    },
    // 进度条宽度
    progressW () {
      if (!this.total) {
        return '0'
      }
      if (!this.current) {
        return '0'
      }
      if (this.current > this.total) {
        return '100%'
      }
      return (this.current / this.total) * 100 + '%'
    }
  }
}
